<template>
  <div class="grid">
    <div class="col-12">
      <div class="card">
        <Toast />
        <Toolbar class="mb-4">
          <template v-slot:start>
            <div class="my-2">
              <Button label="Nova Unidade" icon="pi pi-plus" class="p-button-success mr-2" @click="openNew" />
              <Button label="Apagar" icon="pi pi-trash" class="p-button-danger" @click="confirmDeleteSelected" :disabled="!selectUnidades || !selectUnidades.length" />
            </div>
          </template>

          <template v-slot:end>
            <!-- <FileUpload mode="basic" accept="image/*" :maxFileSize="1000000" label="Import" chooseLabel="Import" class="mr-2 inline-block" />-->
            <Button label="Exportar" icon="pi pi-upload" class="p-button-help" @click="exportCSV($event)" />
          </template>
        </Toolbar>

        <!-- Tabela de Unidades -->
        <DataTable ref="dt" :value="unidades" v-model:selection="selectUnidades" dataKey="id" :paginator="true" :rows="50" :filters="filters" paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown" :rowsPerPageOptions="[5, 10, 25]" currentPageReportTemplate="Mostrando {first} de {last} para {totalRecords} resultado(s)" responsiveLayout="scroll">
          <template #header>
            <div class="flex flex-column md:flex-row md:justify-content-between md:align-items-center">
              <h5 class="m-0">Gerenciar Unidades</h5>
              <span class="block mt-2 md:mt-0 p-input-icon-left">
                <i class="pi pi-search" />
                <InputText v-model="filters['global'].value" placeholder="Buscar" />
              </span>
            </div>
          </template>
          <Column selectionMode="multiple" headerStyle="width: 3rem"></Column>
          <Column field="id" header="ID" :sortable="true" headerStyle="width:14%; min-width:5rem;">
            <template #body="slotProps">
              <span class="p-column-title">Id</span>
              {{ slotProps.data.id }}
            </template>
          </Column>
          <Column field="empresa" header="Empresa" :sortable="true" headerStyle="width:30%; min-width:8rem;">
            <template #body="slotProps">
              <span class="p-column-title">Empresa</span>
              {{ slotProps.data.empresa }}
            </template>
          </Column>
          <Column field="concessionaria" header="Concessionaria" :sortable="true" headerStyle="width:30%; min-width:8rem;">
            <template #body="slotProps">
              <span class="p-column-title">Concessionaria</span>
              {{ slotProps.data.concessionaria }}
            </template>
          </Column>
          <Column field="usina" header="Usina" :sortable="true" headerStyle="width:30%; min-width:8rem;">
            <template #body="slotProps">
              <span class="p-column-title">Usina</span>
              {{ slotProps.data.usina }}
            </template>
          </Column>
          <Column field="status" header="status" :sortable="true" headerStyle="width:30%; min-width:8rem;">
            <template #body="slotProps">
              <span class="p-column-title">Status</span>
              {{ slotProps.data.status }}
            </template>
          </Column>
          <Column field="unidade" header="unidade" :sortable="true" headerStyle="width:30%; min-width:8rem;">
            <template #body="slotProps">
              <span class="p-column-title">Unidade</span>
              {{ slotProps.data.unidade }}
            </template>
          </Column>
          <Column field="tipo_classe_tarifaria" header="Classe Tarifaria" :sortable="true" headerStyle="width:30%; min-width:8rem;">
            <template #body="slotProps">
              <span class="p-column-title">tipo_classe_tarifaria</span>
              {{ slotProps.data.tipo_classe_tarifaria }}
            </template>
          </Column>
          <Column field="tipo_ligacao" header="Ligação" :sortable="true" headerStyle="width:30%; min-width:8rem;">
            <template #body="slotProps">
              <span class="p-column-title">tipo_ligacao</span>
              {{ slotProps.data.tipo_ligacao }}
            </template>
          </Column>
          <Column field="tipo_modalidade_tarifaria" header="Modalidade" :sortable="true" headerStyle="width:30%; min-width:8rem;">
            <template #body="slotProps">
              <span class="p-column-title">tipo_modalidade_tarifaria</span>
              {{ slotProps.data.tipo_modalidade_tarifaria }}
            </template>
          </Column>
          <Column field="tipo_pagamento" header="Pagamento" :sortable="true" headerStyle="width:30%; min-width:8rem;">
            <template #body="slotProps">
              <span class="p-column-title">tipo_pagamento</span>
              {{ slotProps.data.tipo_pagamento }}
            </template>
          </Column>
          <Column field="tipo_subclasse" header="Subclasse" :sortable="true" headerStyle="width:30%; min-width:8rem;">
            <template #body="slotProps">
              <span class="p-column-title">tipo_subclasse</span>
              {{ slotProps.data.tipo_subclasse }}
            </template>
          </Column>
          <Column field="tipo_tensao" header="Tensão" :sortable="true" headerStyle="width:30%; min-width:8rem;">
            <template #body="slotProps">
              <span class="p-column-title">tipo_tensao</span>
              {{ slotProps.data.tipo_tensao }}
            </template>
          </Column>

          <Column headerStyle="min-width:15rem;">
            <template #body="slotProps">
              <Button icon="pi pi-search" class="p-button-rounded p-button-success mr-2" @click="listUnidade(slotProps.data)" />
              <Button icon="pi pi-pencil" class="p-button-rounded p-button-success mr-2" @click="editProduct(slotProps.data)" />
              <Button icon="pi pi-trash" class="p-button-rounded p-button-warning mt-2" @click="deleteUnidadeModal(slotProps.data)" />
            </template>
          </Column>
        </DataTable>

        <!-- ############################################################################################################################################################################ -->
        <!-- Modais de Visualização-->
        <!-- Modal de Visualização de tabelas -->
        <Dialog v-model:visible="modalDadosAdicionaisUnidade" header="Dados adicionais" :style="{ width: '75vw' }" maximizable modal :contentStyle="{ height: '300px' }">
          <TabView>
            <TabPanel header="Endereços">
              <Button label="Novo Endereço" icon="pi pi-plus" class="p-button-success mr-2" @click="addEndereco(listaunidade)" />
              <p> </p>
              <DataTable :value="listaunidade.enderecos_unidade" tableStyle="min-width: 50rem" >
                <Column field="id" header="ID" :sortable="true" headerStyle="width:30%; min-width:8rem;">
                  <template #body="enderecos_unidade">
                    <span class="p-column-title">unidade</span>
                    {{ enderecos_unidade.data.id }}
                  </template>
                </Column>
                <!--
                  <Column field="id_tipo" header="Id Tipo" :sortable="true" headerStyle="width:30%; min-width:8rem;">
                    <template #body="enderecos_unidade">
                      <span class="p-column-title">id_tipo</span>
                      {{ enderecos_unidade.data.id_tipo }}
                    </template>
                  </Column>
                -->
                <Column field="endereco_descricao" header="Tipo Endereço" :sortable="true" headerStyle="width:30%; min-width:8rem;">
                  <template #body="enderecos_unidade">
                    <span class="p-column-title">endereco_descricao</span>
                    {{ enderecos_unidade.data.endereco_descricao }}
                  </template>
                </Column>
                <Column field="cidade_descricao" header="Cidade" :sortable="true" headerStyle="width:30%; min-width:8rem;">
                  <template #body="enderecos_unidade">
                    <span class="p-column-title">cidade_descricao</span>
                    {{ enderecos_unidade.data.cidade_descricao }}
                  </template>
                </Column>
                <Column field="estado_descricao" header="Estado" :sortable="true" headerStyle="width:30%; min-width:8rem;">
                  <template #body="enderecos_unidade">
                    <span class="p-column-title">estado_descricao</span>
                    {{ enderecos_unidade.data.estado_descricao }}
                  </template>
                </Column>
                <Column field="rua" header="Rua" :sortable="true" headerStyle="width:30%; min-width:8rem;">
                  <template #body="enderecos_unidade">
                    <span class="p-column-title">rua</span>
                    {{ enderecos_unidade.data.rua }}
                  </template>
                </Column>
                <Column field="numero" header="Numero" :sortable="true" headerStyle="width:30%; min-width:8rem;">
                  <template #body="enderecos_unidade">
                    <span class="p-column-title">numero</span>
                    {{ enderecos_unidade.data.numero }}
                  </template>
                </Column>
                <Column field="bairro" header="Bairro" :sortable="true" headerStyle="width:30%; min-width:8rem;">
                  <template #body="enderecos_unidade">
                    <span class="p-column-title">bairro</span>
                    {{ enderecos_unidade.data.bairro }}
                  </template>
                </Column>
                <Column field="cep" header="CEP" :sortable="true" headerStyle="width:30%; min-width:8rem;">
                  <template #body="enderecos_unidade">
                    <span class="p-column-title">cep</span>
                    {{ enderecos_unidade.data.cep }}
                  </template>
                </Column>
                <Column field="latitude" header="Latitude" :sortable="true" headerStyle="width:30%; min-width:8rem;">
                  <template #body="enderecos_unidade">
                    <span class="p-column-title">latitude</span>
                    {{ enderecos_unidade.data.latitude }}
                  </template>
                </Column>
                <Column field="longitude" header="Longitude" :sortable="true" headerStyle="width:30%; min-width:8rem;">
                  <template #body="enderecos_unidade">
                    <span class="p-column-title">longitude</span>
                    {{ enderecos_unidade.data.longitude }}
                  </template>
                </Column>
                <Column headerStyle="min-width:15rem;">
                  <template #body="enderecos_unidade">
                    <Button icon="pi pi-pencil" class="p-button-rounded p-button-success mr-2" @click="editEndereco(enderecos_unidade.data)" />
                    <Button icon="pi pi-trash" class="p-button-rounded p-button-warning mt-2" @click="deleteEnderecoModal(enderecos_unidade.data)" />
                  </template>
                </Column>
              </DataTable>
            </TabPanel>

            <TabPanel header="CNPJ">
              <Button label="Novo CNPJ" icon="pi pi-plus" class="p-button-success mr-2" @click="addCnpj(listaunidade)" />
              <p> </p>
              <DataTable :value="listaunidade.cnpjs_unidade" tableStyle="min-width: 50rem" >
                <!--
                  <Column field="id_tipo_cnpj" header="Id tipo Cnpj" :sortable="true" headerStyle="width:30%; min-width:8rem;">
                    <template #body="cnpjs_unidade">
                      <span class="p-column-title">id_tipo_cnpj</span>
                      {{ cnpjs_unidade.data.id_tipo_cnpj }}
                    </template>
                  </Column>

                -->
                <Column field="cnpj_descricao" header="Tipo CNPJ" :sortable="true" headerStyle="width:30%; min-width:8rem;">
                  <template #body="cnpjs_unidade">
                    <span class="p-column-title">cnpj_descricao</span>
                    {{ cnpjs_unidade.data.cnpj_descricao }}
                  </template>
                </Column>
                <Column field="cnpj" header="CNPJ" :sortable="true" headerStyle="width:30%; min-width:8rem;">
                  <template #body="cnpjs_unidade">
                    <span class="p-column-title">cnpj</span>
                    {{ cnpjs_unidade.data.cnpj }}
                  </template>
                </Column>
                <Column headerStyle="min-width:15rem;">
                  <template #body="cnpjs_unidade">
                    <Button icon="pi pi-pencil" class="p-button-rounded p-button-success mr-2" @click="editCnpj(cnpjs_unidade.data)" />
                    <Button icon="pi pi-trash" class="p-button-rounded p-button-warning mt-2" @click="deleteCnpjModal(cnpjs_unidade.data)" />
                  </template>
                </Column>
              </DataTable>
            </TabPanel>

            <TabPanel header="Rateio">
              <Button label="Novo Rateio" icon="pi pi-plus" class="p-button-success mr-2" @click="addRateio(listaunidade)" />
              <p> </p>
              <DataTable :value="listaunidade.rateios_unidade" tableStyle="min-width: 50rem" >
                <Column field="id_unidade" header="Id Unidade" :sortable="true" headerStyle="width:30%; min-width:8rem;">
                  <template #body="rateios_unidade">
                    <span class="p-column-title">id_unidade</span>
                    {{ rateios_unidade.data.id_unidade }}
                  </template>
                </Column>
                <Column field="usina_descricao" header="Usina" :sortable="true" headerStyle="width:30%; min-width:8rem;">
                  <template #body="rateios_unidade">
                    <span class="p-column-title">usina_descricao</span>
                    {{ rateios_unidade.data.usina_descricao }}
                  </template>
                </Column>
                <Column field="data_inicio" header="CNPJ" :sortable="true" headerStyle="width:30%; min-width:8rem;">
                  <template #body="rateios_unidade">
                    <span class="p-column-title">data_inicio</span>
                    {{ rateios_unidade.data.data_inicio }}
                  </template>
                </Column>
                <Column field="data_termino" header="CNPJ" :sortable="true" headerStyle="width:30%; min-width:8rem;">
                  <template #body="rateios_unidade">
                    <span class="p-column-title">data_termino</span>
                    {{ rateios_unidade.data.data_termino }}
                  </template>
                </Column>
                <Column headerStyle="min-width:15rem;">
                  <template #body="rateios_unidade">
                    <Button icon="pi pi-pencil" class="p-button-rounded p-button-success mr-2" @click="editRateio(rateios_unidade.data)" />
                    <Button icon="pi pi-trash" class="p-button-rounded p-button-warning mt-2" @click="deleteRateioModal(rateios_unidade.data)" />
                  </template>
                </Column>
              </DataTable>
            </TabPanel>
          </TabView>
          
          <template #footer>
            <Button label="Ok" icon="pi pi-check" @click="modalDadosAdicionaisUnidade = false" />
          </template>
        </Dialog>
        
        <!-- ############################################################################################################################################################################ -->
        <!-- Modais de Edição-->
        <!-- Modal de edição de tipo de concess -->
        <Dialog v-model:visible="modalEditUnidade" :style="{ width: '950px' }" header="Editar Unidade" :modal="true" class="p-fluid">
          <div class="grid">
            <div class="field col-6">
              <label for="name">Unidade</label>
              <InputText id="unidade" v-model.trim="dados_unidade.unidade" required="true" autofocus :class="{ 'p-invalid': submitted && !unidade.unidade }" />
              <small class="p-invalid" v-if="submitted && !unidade.unidade">Razão Social é obrigatória.</small>
            </div>
            <div class="field col-6">
              <label for="name">Status</label>
              <InputText id="status" v-model.trim="dados_unidade.status" required="true" autofocus :class="{ 'p-invalid': submitted && !unidade.status }" />
              <small class="p-invalid" v-if="submitted && !unidade.status">Razão Social é obrigatória.</small>
            </div>
          </div>
          <div class="grid">
            <div class="field col-3">
              <label for="Tipo" class="mb-3">Empresa</label>
              <Dropdown id="id_empresa" required="true" v-model="dados_unidade.id_empresa" :options="tipos_empresas" optionLabel="label" optionValue="value" placeholder="Selecione um tipo" :class="{ 'p-invalid': submitted && !unidade.id_empresa }"></Dropdown>
              <small class="p-invalid" v-if="submitted && !unidade.id_empresa">Empresa é obrigatório.</small>
            </div>
            <div class="field col-6">
              <label for="Tipo" class="mb-3">Concessionaria</label>
              <Dropdown id="id_concessionaria" required="true" v-model="dados_unidade.id_concessionaria" :options="tipos_concessionarias" optionLabel="label" optionValue="value" placeholder="Selecione um tipo" :class="{ 'p-invalid': submitted && !unidade.id_concessionaria }"></Dropdown>
              <small class="p-invalid" v-if="submitted && !unidade.id_concessionaria">Concessionaria é obrigatório.</small>
            </div>
            <div class="field col-3">
              <label for="Tipo" class="mb-3">Usinas</label>
              <Dropdown id="id_usina" required="true" v-model="dados_unidade.id_usina" :options="tipos_usinas" optionLabel="label" optionValue="value" placeholder="Selecione um tipo" :class="{ 'p-invalid': submitted && !unidade.id_usina }"></Dropdown>
              <small class="p-invalid" v-if="submitted && !unidade.id_usina">Concessionaria é obrigatório.</small>
            </div>
          </div>
          <Divider />
          <div class="grid">
            <div class="field col-3">
              <label for="Tipo" class="mb-3">Classe Tarifaria</label>
              <Dropdown id="id_tipo_classe_tarifaria" required="true" v-model="dados_unidade.id_tipo_classe_tarifaria" :options="tipos_classe_tarifaria" optionLabel="label" optionValue="value" placeholder="Selecione um tipo" :class="{ 'p-invalid': submitted && !unidade.id_tipo_classe_tarifaria }"></Dropdown>
              <small class="p-invalid" v-if="submitted && !unidade.id_tipo_classe_tarifaria">Concessionaria é obrigatório.</small>
            </div>
            <div class="field col-3">
              <label for="Tipo" class="mb-3">Ligacao</label>
              <Dropdown id="id_tipo_ligacao" required="true" v-model="dados_unidade.id_tipo_ligacao" :options="tipos_ligacao" optionLabel="label" optionValue="value" placeholder="Selecione um tipo" :class="{ 'p-invalid': submitted && !unidade.id_tipo_ligacao }"></Dropdown>
              <small class="p-invalid" v-if="submitted && !unidade.id_tipo_ligacao">Concessionaria é obrigatório.</small>
            </div>
            <div class="field col-3">
              <label for="Tipo" class="mb-3">Modalidade</label>
              <Dropdown id="id_tipo_modalidade_tarifaria" required="true" v-model="dados_unidade.id_tipo_modalidade_tarifaria" :options="tipos_modalidade" optionLabel="label" optionValue="value" placeholder="Selecione um tipo" :class="{ 'p-invalid': submitted && !unidade.id_tipo_modalidade_tarifaria }"></Dropdown>
              <small class="p-invalid" v-if="submitted && !unidade.id_tipo_modalidade_tarifaria">Concessionaria é obrigatório.</small>
            </div>
            <div class="field col-3">
              <label for="Tipo" class="mb-3">Pagamento</label>
              <Dropdown id="id_tipo_pagamento" required="true" v-model="dados_unidade.id_tipo_pagamento" :options="tipos_pagamento" optionLabel="label" optionValue="value" placeholder="Selecione um tipo" :class="{ 'p-invalid': submitted && !unidade.id_tipo_pagamento }"></Dropdown>
              <small class="p-invalid" v-if="submitted && !unidade.id_tipo_pagamento">Concessionaria é obrigatório.</small>
            </div>
          </div>
          <div class="grid">
            <div class="field col-3">
              <label for="Tipo" class="mb-3">SubClasse</label>
              <Dropdown id="id_tipo_sub_classe" required="true" v-model="dados_unidade.id_tipo_sub_classe" :options="tipos_subclasse" optionLabel="label" optionValue="value" placeholder="Selecione um tipo" :class="{ 'p-invalid': submitted && !unidade.id_tipo_sub_classe }"></Dropdown>
              <small class="p-invalid" v-if="submitted && !unidade.id_tipo_sub_classe">Concessionaria é obrigatório.</small>
            </div>
            <div class="field col-3">
              <label for="Tipo" class="mb-3">Tensão</label>
              <Dropdown id="id_tipo_tensao" required="true" v-model="dados_unidade.id_tipo_tensao" :options="tipos_tensao" optionLabel="label" optionValue="value" placeholder="Selecione um tipo" :class="{ 'p-invalid': submitted && !unidade.id_tipo_tensao }"></Dropdown>
              <small class="p-invalid" v-if="submitted && !unidade.id_tipo_tensao">Concessionaria é obrigatório.</small>
            </div>
          </div>

          <Divider />
          <template #footer>
            <Button label="Cancelar" icon="pi pi-times" class="p-button-secondary p-button-sm" @click="hideEditUnidade" />
            <Button label="Salvar" icon="pi pi-check" class="p-button-success p-button-sm" @click="salvarUnidade" />
          </template>
        </Dialog>

        <!-- Modal de edição de endereço-->
        <Dialog v-model:visible="modalEditEndereco" :style="{ width: '1050px' }" header="Editar Medidor" :modal="true" class="p-fluid">
          <div class="grid">
            <div class="field col-6">
              <label for="name">ID Unidade</label>
              <InputText id="id_unidade" v-model.trim="dados_endereco.id_unidade" required="true" disabled autofocus :class="{ 'p-invalid': submitted && !dados_endereco.id_unidade }" />
              <small class="p-invalid" v-if="submitted && !dados_endereco.id_unidade">Razão Social é obrigatória.</small>
            </div>
          </div>
          <div class="grid">
            <div class="field col-4">
              <label for="id_tipo" class="mb-3">Tipo de Endereço</label>
              <Dropdown id="id_tipo" required="true" v-model="dados_endereco.id_tipo" :options="tipos_enderecos" optionLabel="label" optionValue="value" placeholder="Selecione um tipo" :class="{ 'p-invalid': submitted && !dados_endereco.id_tipo }"></Dropdown>
              <small class="p-invalid" v-if="submitted && !dados_endereco.id_tipo">Empresa é obrigatório.</small>
            </div>
            <div class="field col-4">
              <label for="Tipo" class="mb-3">Estado</label>
              <Dropdown id="id_estado" required="true" v-model="dados_endereco.id_estado" :options="tipos_estado" optionLabel="label" optionValue="value" placeholder="Selecione um tipo" :class="{ 'p-invalid': submitted && !dados_endereco.id_estado }" @change="listarCidade()"></Dropdown>
              <small class="p-invalid" v-if="submitted && !dados_endereco.id_estado">Empresa é obrigatório.</small>
            </div>
            <div class="field col-4">
              <label for="Tipo" class="mb-3">Cidade</label>
              <Dropdown id="id_cidade" required="true" v-model="dados_endereco.id_cidade" :options="tipos_cidade" optionLabel="label" optionValue="value" placeholder="Selecione um tipo" :class="{ 'p-invalid': submitted && !dados_endereco.id_cidade }"></Dropdown>
              <small class="p-invalid" v-if="submitted && !dados_endereco.id_cidade">Empresa é obrigatório.</small>
            </div>
          </div>
          <div class="grid">
            <div class="field col-10">
              <label for="name">Rua</label>
              <InputText id="rua" v-model.trim="dados_endereco.rua" required="true" autofocus :class="{ 'p-invalid': submitted && !dados_endereco.rua }" />
              <small class="p-invalid" v-if="submitted && !dados_endereco.rua">Razão Social é obrigatória.</small>
            </div>
            <div class="field col-2">
              <label for="name">Numero</label>
              <InputText id="numero" v-model.trim="dados_endereco.numero" required="true" autofocus :class="{ 'p-invalid': submitted && !dados_endereco.numero }" />
              <small class="p-invalid" v-if="submitted && !dados_endereco.numero">Razão Social é obrigatória.</small>
            </div>
          </div>
          <div class="grid">
            <div class="field col-6">
              <label for="name">Bairro</label>
              <InputText id="bairro" v-model.trim="dados_endereco.bairro" required="true" autofocus :class="{ 'p-invalid': submitted && !dados_endereco.bairro }" />
              <small class="p-invalid" v-if="submitted && !dados_endereco.bairro">Razão Social é obrigatória.</small>
            </div>
            <div class="field col-6">
              <label for="name">CEP</label>
              <InputText id="cep" v-model.trim="dados_endereco.cep" required="true" autofocus :class="{ 'p-invalid': submitted && !dados_endereco.cep }" />
              <small class="p-invalid" v-if="submitted && !dados_endereco.cep">Razão Social é obrigatória.</small>
            </div>
          </div>
          <div class="grid">
            <div class="field col-6">
              <label for="name">Latitude</label>
              <InputText id="latitude" v-model.trim="dados_endereco.latitude" required="true" autofocus :class="{ 'p-invalid': submitted && !dados_endereco.latitude }" />
              <small class="p-invalid" v-if="submitted && !dados_endereco.latitude">Razão Social é obrigatória.</small>
            </div>
            <div class="field col-6">
              <label for="name">Longitude</label>
              <InputText id="longitude" v-model.trim="dados_endereco.longitude" required="true" autofocus :class="{ 'p-invalid': submitted && !dados_endereco.longitude }" />
              <small class="p-invalid" v-if="submitted && !dados_endereco.longitude">Razão Social é obrigatória.</small>
            </div>
          </div>
          <template #footer>
            <Button label="Cancelar" icon="pi pi-times" class="p-button-secondary p-button-sm" @click="hideEditEndereco" />
            <Button label="Salvar" icon="pi pi-check" class="p-button-success p-button-sm" @click="salvarEndereco" />
          </template>
        </Dialog>

        <!-- Modal de edição de CNPJ-->
        <Dialog v-model:visible="modalEditCnpj" :style="{ width: '1050px' }" header="Editar Cnpj" :modal="true" class="p-fluid">
          <div class="field">
            <label for="name">ID unidade</label>
            <InputText id="id_unidade" v-model.trim="dados_cnpj.id_unidade" required="true" disabled autofocus :class="{ 'p-invalid': submitted && !dados_cnpj.id_unidade }" />
            <small class="p-invalid" v-if="submitted && !dados_cnpj.id_unidade">Razão Social é obrigatória.</small>
          </div>
          <div class="grid">
            <div class="field col-4">
              <label for="id_tipo_cnpj" class="mb-3">Tipo de Endereço</label>
              <Dropdown id="id_tipo_cnpj" required="true" v-model="dados_cnpj.id_tipo_cnpj" :options="tipos_cnpj" optionLabel="label" optionValue="value" placeholder="Selecione um tipo" :class="{ 'p-invalid': submitted && !dados_cnpj.id_tipo_cnpj }"></Dropdown>
              <small class="p-invalid" v-if="submitted && !dados_cnpj.id_tipo_cnpj">Empresa é obrigatório.</small>
            </div>
          </div>
          <div class="grid">
            <div class="field col-8">
              <label for="name">CNPJ</label>
              <InputText id="cnpj" v-model.trim="dados_cnpj.cnpj" required="true" autofocus :class="{ 'p-invalid': submitted && !dados_cnpj.cnpj }" />
              <small class="p-invalid" v-if="submitted && !dados_cnpj.cnpj">Razão Social é obrigatória.</small>
            </div>
          </div>
          <template #footer>
            <Button label="Cancelar" icon="pi pi-times" class="p-button-secondary p-button-sm" @click="hideEditCnpj" />
            <Button label="Salvar" icon="pi pi-check" class="p-button-success p-button-sm" @click="salvarCnpj" />
          </template>
        </Dialog>

        <!-- Modal de edição de Rateio-->
        <Dialog v-model:visible="modalEditRateio" :style="{ width: '1050px' }" header="Editar Rateio" :modal="true" class="p-fluid">
          <div class="field">
            <label for="name">ID unidade</label>
            <InputText id="id_unidade" v-model.trim="dados_rateio.id_unidade" required="true" disabled autofocus :class="{ 'p-invalid': submitted && !dados_rateio.id_unidade }" />
            <small class="p-invalid" v-if="submitted && !dados_rateio.id_unidade">Razão Social é obrigatória.</small>
          </div>
          <div class="grid">
            <div class="field col-6">
              <label for="name">ID Usina</label>
              <InputText id="id_usina" v-model.trim="dados_rateio.id_usina" required="true" autofocus :class="{ 'p-invalid': submitted && !dados_rateio.id_usina }" />
              <small class="p-invalid" v-if="submitted && !dados_rateio.id_usina">Razão Social é obrigatória.</small>
            </div>
            <div class="field col-6">
              <label for="name">Valor</label>
              <InputText id="valor_rateio" v-model.trim="dados_rateio.valor_rateio" required="true" autofocus :class="{ 'p-invalid': submitted && !dados_rateio.valor_rateio }" />
              <small class="p-invalid" v-if="submitted && !dados_rateio.valor_rateio">Razão Social é obrigatória.</small>
            </div>
          </div>
          <div class="grid">
            <div class="field col-6">
              <label for="name">Data Inicio</label>
              <Calendar id="data_inicio" v-model="dados_rateio.data_inicio" dateFormat="dd/mm/yy" :class="{ 'p-invalid': submitted && !dados_rateio.data_inicio }"/>
              <small class="p-invalid" v-if="submitted && !dados_rateio.data_inicio">Razão Social é obrigatória.</small>
            </div>
            <div class="field col-6">
              <label for="name">Data Termino</label>
              <Calendar id="data_termino" v-model="dados_rateio.data_termino" dateFormat="yy-mm-dd" :class="{ 'p-invalid': submitted && !dados_rateio.data_termino }"/>
              <small class="p-invalid" v-if="submitted && !dados_rateio.data_termino">Razão Social é obrigatória.</small>
            </div>
          </div>
          <template #footer>
            <Button label="Cancelar" icon="pi pi-times" class="p-button-secondary p-button-sm" @click="hideEditRateio" />
            <Button label="Salvar" icon="pi pi-check" class="p-button-success p-button-sm" @click="salvarRateio" />
          </template>
        </Dialog>

        <!-- ############################################################################################################################################################################ -->
        <!-- Modais de Delete-->
        <!-- Modal deletar Unidades -->
        <Dialog v-model:visible="deleteProductDialog" :style="{ width: '450px' }" :modal="true">
          <div class="flex align-items-center justify-content-center">
            <i class="pi pi-exclamation-triangle mr-3" style="font-size: 2rem" />
            <span v-if="unidade">Certeza que vai apagar: <b>{{ unidade.descricao }}</b>?</span>
          </div>
          <template #footer class="align-items-center">
            <Button label="Não" icon="pi pi-times" class="p-button-secondary p-button-sm" @click=" deleteProductDialog = false" />
            <Button label="Sim" icon="pi pi-check" class="p-button-danger p-button-sm" @click="apagarUnidade" />
          </template>
        </Dialog>

        <!-- Modal deletar Enderecos -->
        <Dialog v-model:visible="deleteEnderecoDialog" :style="{ width: '450px' }" :modal="true">
          <div class="flex align-items-center justify-content-center">
            <i class="pi pi-exclamation-triangle mr-3" style="font-size: 2rem" />
            <span v-if="dados_endereco">Certeza que vai apagar: <b>{{ dados_endereco.rua }}</b>?</span>
          </div>
          <template #footer class="align-items-center">
            <Button label="Não" icon="pi pi-times" class="p-button-secondary p-button-sm" @click=" deleteEnderecoDialog = false" />
            <Button label="Sim" icon="pi pi-check" class="p-button-danger p-button-sm" @click="apagarEndereco" />
          </template>
        </Dialog>

        <!-- Modal deletar CNPJ -->
        <Dialog v-model:visible="deleteCnpjDialog" :style="{ width: '450px' }" :modal="true">
          <div class="flex align-items-center justify-content-center">
            <i class="pi pi-exclamation-triangle mr-3" style="font-size: 2rem" />
            <span v-if="dados_cnpj">Certeza que vai apagar: <b>{{ dados_cnpj.id }}</b>?</span>
          </div>
          <template #footer class="align-items-center">
            <Button label="Não" icon="pi pi-times" class="p-button-secondary p-button-sm" @click=" deleteCnpjDialog = false" />
            <Button label="Sim" icon="pi pi-check" class="p-button-danger p-button-sm" @click="apagarCnpj" />
          </template>
        </Dialog>

        <!-- Modal deletar Rateios -->
        <Dialog v-model:visible="deleteRateioDialog" :style="{ width: '450px' }" :modal="true">
          <div class="flex align-items-center justify-content-center">
            <i class="pi pi-exclamation-triangle mr-3" style="font-size: 2rem" />
            <span v-if="dados_rateio">Certeza que vai apagar: <b>{{ dados_rateio.id }}</b>?</span>
          </div>
          <template #footer class="align-items-center">
            <Button label="Não" icon="pi pi-times" class="p-button-secondary p-button-sm" @click=" deleteRateioDialog = false" />
            <Button label="Sim" icon="pi pi-check" class="p-button-danger p-button-sm" @click="apagarRateio" />
          </template>
        </Dialog>

        <!-- Modal deletar varias Unidades -->
        <Dialog v-model:visible="deleteProductsDialog" :style="{ width: '450px' }" header="" :modal="true">
          <div class="flex align-items-center justify-content-center">
            <i class="pi pi-exclamation-triangle mr-3" style="font-size: 2rem" />
            <span v-if="unidade">Apagar Tipos de Concessionarias selecionados?</span>
          </div>
          <template #footer>
            <Button label="Não" icon="pi pi-times" class="p-button-text" @click="deleteProductsDialog = false" />
            <Button label="Sim" icon="pi pi-check" class="p-button-text" @click="deleteSelectedProducts" />
          </template>
        </Dialog>

      </div>
    </div>
  </div>
</template>

<script>

  import { FilterMatchMode } from "primevue/api";

  export default {
    data() {
      return {
        unidades: [],
        unidade: {},
        listaunidade: {},
        selectUnidades: null,
        

        deleteProductDialog: false,
        deleteProductsDialog: false,
        deleteEnderecoDialog: false,
        deleteCnpjDialog: false,
        deleteRateioDialog: false,

        modalDadosAdicionaisUnidade: false,
        modalEditUnidade: false,
        modalEditEndereco: false,
        modalEditCnpj: false,
        modalEditRateio: false,

        dados_unidade: {},
        dados_endereco:{},
        dados_cnpj:{},
        dados_rateio:{},

        filters: {},
        submitted: false,
        tipos_empresas: [],
        desc_tipos_empresas: [],
        tipos_concessionarias: [],
        tipos_usinas: [],
        tipos_classe_tarifaria: [],
        tipos_ligacao: [],
        tipos_modalidade: [],
        tipos_pagamento: [],
        tipos_subclasse: [],
        tipos_tensao: [],

        tipos_enderecos: [],
        tipos_estado: [],
        tipos_cidade: [],
        
      };
    },

    created() {
      this.initFilters();
    },
    mounted() {
      this.listarUnidades();
      this.listarEmpresas();
      this.listarConcessionarias();
      this.listarUsinas();
      this.listarClasseTarifaria();
      this.listarLigacao();
      this.listarModalidade();
      this.listarPagamento();
      this.listarSubclasse();
      this.listarTensao();
      this.listarEndereco();
      this.listarEstado();
      // this.listarCidade();
      this.listarTipoCnpj();
      

    },


    methods: {
      // listar unidades
      listarUnidades(){
        this.axios
          .post("/unidades/listar")
          .then((response) => {
            this.unidades = response.data.dados;
            console.log(this.unidades)
          })
          .catch(() => {
            self.$toast.add({
              severity: "error",
              summary: "Ops!",
              detail: "Chamar o T.I.",
              life: 3000,
            });
          });
      },

      

      openNew() {
        this.dados_unidade = {};
        this.submitted = false;
        this.modalEditUnidade = true;
      },

      addEndereco(dados_endereco) {
        let id_endereco = dados_endereco.id;
        this.dados_endereco = {};
        this.modalEditEndereco = true;
        this.submitted = false;
        this.dados_endereco.id_unidade = id_endereco ;
      },

      addCnpj(dados_cnpj) {
        let id_cnpj = dados_cnpj.id;
        this.dados_cnpj = {};
        this.modalEditCnpj = true;
        this.submitted = false;
        this.dados_cnpj.id_unidade = id_cnpj ;
      },

      addRateio(dados_rateio) {
        let id_rateio = dados_rateio.id;
        this.dados_rateio = {};
        this.modalEditRateio = true;
        this.submitted = false;
        this.dados_rateio.id_unidade = id_rateio ;
      },





      editProduct(dados_unidade) {
        this.modalEditUnidade = true;
        this.dados_unidade = { ...dados_unidade };

      },

      editEndereco(dados_endereco) {
        this.modalEditEndereco = true;
        this.dados_endereco = { ...dados_endereco };
      },

      editCnpj(dados_cnpj) {
        this.modalEditCnpj = true;
        this.dados_cnpj = { ...dados_cnpj };
      },

      editRateio(dados_rateio) {
        this.modalEditRateio = true;
        this.dados_rateio = { ...dados_rateio };
      },

      listUnidade(listaunidade) {
        // this.listarEnderecoUnidade
        this.modalDadosAdicionaisUnidade = true;
        this.listaunidade = { ...listaunidade};

      },






      



      

      deleteUnidadeModal(unidade) {
        this.unidade = unidade;
        this.deleteProductDialog = true;
      },

      deleteEnderecoModal(dados_endereco) {
        this.dados_endereco = dados_endereco;
        this.deleteEnderecoDialog = true;
      },

      deleteCnpjModal(dados_cnpj) {
        this.dados_cnpj = dados_cnpj;
        this.deleteCnpjDialog = true;
      },

      deleteRateioModal(dados_rateio) {
        this.dados_rateio = dados_rateio;
        this.deleteRateioDialog = true;
      },



      hideEditUnidade() {
        this.modalEditUnidade = false;
        this.submitted = false;
      },

      hideEditEndereco() {
        this.modalEditEndereco = false;
        this.submitted = false;
      },

      hideEditCnpj() {
        this.modalEditCnpj = false;
        this.submitted = false;
      },

      hideEditRateio() {
        this.modalEditRateio = false;
        this.submitted = false;
      },


















      // ##########################################################################################################################
      // Funçoes Listar


      listarEmpresas() {
        this.axios
          .post("/empresas/listar")
          .then((response) => {

            let options_empresas = [];
            let desc_empresas = {};
            response.data.dados.map((el_empresas) => {

              let option_empresas = { value: el_empresas["id"], label: el_empresas["razao_social"] };
              desc_empresas[el_empresas["id"]] = el_empresas["razao_social"];
              options_empresas.push(option_empresas);

            });
            this.tipos_empresas = options_empresas;
            this.desc_tipos_empresas = desc_empresas;

            console.log(this.desc_tipos_empresas)
          })
          .catch((error) => {
            console.log(error);
          });
      },

      listarConcessionarias() {
        this.axios
          .get("/concessionarias/listar")
          .then((response) => {

            let options_concessionarias = [];
            let desc_concessionarias = {};
            response.data.dados.map((el_concessionarias) => {

              let option_concessionarias = { value: el_concessionarias["id"], label: el_concessionarias["sigla"] };
              desc_concessionarias[el_concessionarias["id"]] = el_concessionarias["sigla"];
              options_concessionarias.push(option_concessionarias);

            });
            this.tipos_concessionarias = options_concessionarias;
            this.desc_tipos_concessionarias = desc_concessionarias;

            console.log(this.desc_tipos_concessionarias)
          })
          .catch((error) => {
            console.log(error);
          });
      },

      listarUsinas() {
        this.axios
          .post("/usinas/listar")
          .then((response) => {

            let options_usinas = [];
            let desc_usinas = {};
            response.data.dados.map((el_usinas) => {

              let option_usinas = { value: el_usinas["id"], label: el_usinas["sigla"] };
              desc_usinas[el_usinas["id"]] = el_usinas["sigla"];
              options_usinas.push(option_usinas);

            });
            this.tipos_usinas = options_usinas;
            this.desc_tipos_usinas = desc_usinas;

            console.log(this.desc_tipos_usinas)
          })
          .catch((error) => {
            console.log(error);
          });
      },

      listarClasseTarifaria() {
        this.axios
          .post("/unidades/dados/tipos-classe-tarifaria/listar")
          .then((response) => {

            let options_classe_tarifaria = [];
            let desc_classe_tarifaria = {};
            response.data.dados.map((el_classe_tarifaria) => {

              let option_classe_tarifaria = { value: el_classe_tarifaria["id"], label: el_classe_tarifaria["descricao"] };
              desc_classe_tarifaria[el_classe_tarifaria["id"]] = el_classe_tarifaria["descricao"];
              options_classe_tarifaria.push(option_classe_tarifaria);

            });
            this.tipos_classe_tarifaria = options_classe_tarifaria;
            this.desc_tipos_classe_tarifaria = desc_classe_tarifaria;

            console.log(this.desc_tipos_classe_tarifaria)
          })
          .catch((error) => {
            console.log(error);
          });
      },

      listarLigacao() {
        this.axios
          .post("/unidades/dados/tipos-ligacao/listar")
          .then((response) => {

            let options_ligacao = [];
            let desc_ligacao = {};
            response.data.dados.map((el_ligacao) => {

              let option_ligacao = { value: el_ligacao["id"], label: el_ligacao["descricao"] };
              desc_ligacao[el_ligacao["id"]] = el_ligacao["descricao"];
              options_ligacao.push(option_ligacao);

            });
            this.tipos_ligacao = options_ligacao;
            this.desc_tipos_ligacao = desc_ligacao;

            console.log(this.desc_tipos_ligacao)
          })
          .catch((error) => {
            console.log(error);
          });
      },

      listarModalidade() {
        this.axios
          .post("/unidades/dados/tipos-modalidade-tarifaria/listar")
          .then((response) => {

            let options_modalidade = [];
            let desc_modalidade = {};
            response.data.dados.map((el_modalidade) => {

              let option_modalidade = { value: el_modalidade["id"], label: el_modalidade["descricao"] };
              desc_modalidade[el_modalidade["id"]] = el_modalidade["descricao"];
              options_modalidade.push(option_modalidade);

            });
            this.tipos_modalidade = options_modalidade;
            this.desc_tipos_modalidade = desc_modalidade;

            console.log(this.desc_tipos_modalidade)
          })
          .catch((error) => {
            console.log(error);
          });
      },

      listarPagamento() {
        this.axios
          .post("/unidades/dados/tipos-pagamento/listar")
          .then((response) => {

            let options_pagamento = [];
            let desc_pagamento = {};
            response.data.dados.map((el_pagamento) => {

              let option_pagamento = { value: el_pagamento["id"], label: el_pagamento["descricao"] };
              desc_pagamento[el_pagamento["id"]] = el_pagamento["descricao"];
              options_pagamento.push(option_pagamento);

            });
            this.tipos_pagamento = options_pagamento;
            this.desc_tipos_pagamento = desc_pagamento;

            console.log(this.desc_tipos_pagamento)
          })
          .catch((error) => {
            console.log(error);
          });
      },

      listarSubclasse() {
        this.axios
          .post("/unidades/dados/tipos-sub-classe/listar")
          .then((response) => {

            let options_subclasse = [];
            let desc_subclasse = {};
            response.data.dados.map((el_subclasse) => {

              let option_subclasse = { value: el_subclasse["id"], label: el_subclasse["descricao"] };
              desc_subclasse[el_subclasse["id"]] = el_subclasse["descricao"];
              options_subclasse.push(option_subclasse);

            });
            this.tipos_subclasse = options_subclasse;
            this.desc_tipos_subclasse = desc_subclasse;

            console.log(this.desc_tipos_subclasse)
          })
          .catch((error) => {
            console.log(error);
          });
      },

      listarTensao() {
        this.axios
          .post("/unidades/dados/tipos-tensao/listar")
          .then((response) => {

            let options_tensao = [];
            let desc_tensao = {};
            response.data.dados.map((el_tensao) => {

              let option_tensao = { value: el_tensao["id"], label: el_tensao["descricao"] };
              desc_tensao[el_tensao["id"]] = el_tensao["descricao"];
              options_tensao.push(option_tensao);

            });
            this.tipos_tensao = options_tensao;
            this.desc_tipos_tensao = desc_tensao;

            console.log(this.desc_tipos_tensao)
          })
          .catch((error) => {
            console.log(error);
          });
      },

      listarEndereco() {
        this.axios
          .post("/unidades/enderecos/tipos/listar")
          .then((response) => {

            let options_enderecos = [];
            let desc_enderecos = {};
            response.data.dados.map((el_enderecos) => {

              let option_enderecos = { value: el_enderecos["id"], label: el_enderecos["descricao"] };
              desc_enderecos[el_enderecos["id"]] = el_enderecos["descricao"];
              options_enderecos.push(option_enderecos);

            });
            this.tipos_enderecos = options_enderecos;
            this.desc_tipos_enderecos = desc_enderecos;

            console.log(this.desc_tipos_enderecos)
          })
          .catch((error) => {
            console.log(error);
          });
      },

      listarEstado() {
        this.axios
          .post("/auxiliar/estados")
          .then((response) => {

            let options_estado = [];
            let desc_estado = {};
            response.data.dados.map((el_estado) => {

              let option_estado = { value: el_estado["id"], label: el_estado["nome"] };
              desc_estado[el_estado["id"]] = el_estado["nome"];
              options_estado.push(option_estado);

            });
            this.tipos_estado = options_estado;
            this.desc_tipos_estado = desc_estado;

            console.log(this.desc_tipos_estado)
          })
          .catch((error) => {
            console.log(error);
          });
      },

      listarCidade() {
        let data = {
          state_id: this.dados_endereco.id_estado,
        };
        console.log(data)
          this.axios
          .post("/auxiliar/cidades", data)
          .then((response) => {

            let options_cidade = [];
            let desc_cidade = {};
            response.data.dados.map((el_cidade) => {

              let option_cidade = { value: el_cidade["id"], label: el_cidade["nome"] };
              desc_cidade[el_cidade["id"]] = el_cidade["nome"];
              options_cidade.push(option_cidade);

            });
            this.tipos_cidade = options_cidade;
            this.desc_tipos_cidade = desc_cidade;

            console.log(this.desc_tipos_cidade)
          })
          .catch((error) => {
            console.log(error);
          });
      },

      listarTipoCnpj() {
        this.axios
          .post("/unidades/cnpj/tipos-cnpj/listar")
          .then((response) => {

            let options_cnpj = [];
            let desc_cnpj = {};
            response.data.dados.map((el_cnpj) => {

              let option_cnpj = { value: el_cnpj["id"], label: el_cnpj["descricao"] };
              desc_cnpj[el_cnpj["id"]] = el_cnpj["descricao"];
              options_cnpj.push(option_cnpj);

            });
            this.tipos_cnpj = options_cnpj;
            this.desc_tipos_cnpj = desc_cnpj;

            console.log(this.desc_tipos_cnpj)
          })
          .catch((error) => {
            console.log(error);
          });
      },

      







      // ##########################################################################################################################
      // Funçoes Criar / Editar
      // criar/editar unidade
      salvarUnidade() {
        this.submitted = true;

        if (this.dados_unidade.id) {
          // Ediçao de unidade
          this.axios
            .post("/unidades/editar", this.dados_unidade)
            .then(() => {

              // console.log(this.dados_unidade)

              this.modalEditUnidade = false;
              this.dados_unidade = {};
              this.listarUnidades();
            })
            .catch(() => {
              this.$toast.add({
                severity: "error",
                summary: "Ops!",
                detail: "Chamar o T.I.",
                life: 3000,
              });
            });
        } else {
          // Cadastrar nova unidade
          this.axios
            .post("/unidades/adicionar", this.dados_unidade)
            .then(() => {

              // alert(response.data.data)

              this.modalEditUnidade = false;
              this.dados_unidade = {};
              this.listarUnidades();
            })
            .catch(() => {
              self.$toast.add({
                severity: "error",
                summary: "Ops!",
                detail: "Chamar o T.I.",
                life: 3000,
              });
            });
        }
      },

      // criar/editar endereço da unidade
      salvarEndereco() {
        this.submitted = true;

        if (this.dados_endereco.id) {
          // Ediçao de dados_endereco
          this.axios
            .post("/unidades/enderecos/editar", this.dados_endereco)
            .then(() => {

              // console.log(this.dados_unidade)

              this.modalEditEndereco = false;
              this.dados_endereco = {};
              this.listarEndereco();
            })
            .catch(() => {
              this.$toast.add({
                severity: "error",
                summary: "Ops!",
                detail: "Chamar o T.I.",
                life: 3000,
              });
            });
        } else {
          // Cadastrar nova unidade
          this.axios
            .post("/unidades/enderecos/adicionar", this.dados_endereco)
            .then(() => {

              // alert(response.data.data)

              this.modalEditUnidade = false;
              this.dados_endereco = {};
              this.listarEndereco();
            })
            .catch(() => {
              self.$toast.add({
                severity: "error",
                summary: "Ops!",
                detail: "Chamar o T.I.",
                life: 3000,
              });
            });
        }
      },

      // criar/editar cnpj da unidade
      salvarCnpj() {
        this.submitted = true;

        if (this.dados_cnpj.id) {
          // Ediçao de dados_cnpj
          this.axios
            .post("/unidades/cnpj/editar", this.dados_cnpj)
            .then(() => {
              this.modalEditUnidade = false;
              this.dados_cnpj = {};
              this.listarUnidades();
            })
            .catch(() => {
              this.$toast.add({
                severity: "error",
                summary: "Ops!",
                detail: "Chamar o T.I.",
                life: 3000,
              });
            });
        } else {
          // Cadastrar nova unidade
          this.axios
            .post("/unidades/cnpj/adicionar", this.dados_cnpj)
            .then(() => {
              this.modalEditUnidade = false;
              this.dados_cnpj = {};
              this.listarUnidades();
            })
            .catch(() => {
              self.$toast.add({
                severity: "error",
                summary: "Ops!",
                detail: "Chamar o T.I.",
                life: 3000,
              });
            });
        }
      },

      // criar/editar cnpj da unidade
      salvarRateio() {
        this.submitted = true;

        if (this.dados_rateio.id) {
          // Ediçao de dados_rateio
          this.axios
            .post("/unidades/rateio/editar", this.dados_rateio)
            .then(() => {
              this.modalEditRateio = false;
              //this.modalDadosAdicionaisUnidade = false;
              this.dados_rateio = {};
              this.listarUnidades();
              this.listaunidade.rateios_unidade = this.unidades.rateios_unidade;
            })
            .catch(() => {
              this.$toast.add({
                severity: "error",
                summary: "Ops!",
                detail: "Chamar o T.I.",
                life: 3000,
              });
            });
        } else {
          // Cadastrar nova unidade
          this.axios
            .post("/unidades/rateio/adicionar", this.dados_rateio)
            .then(() => {
              this.modalEditRateio = false;
              //this.modalDadosAdicionaisUnidade = false;
              this.dados_rateio = {};
              this.listarUnidades();
              this.listaunidade.rateios_unidade = this.unidades.rateios_unidade;
            })
            .catch(() => {
              self.$toast.add({
                severity: "error",
                summary: "Ops!",
                detail: "Chamar o T.I.",
                life: 3000,
              });
            });
        }
      },

      // ##########################################################################################################################
      // Funçoes Delete
      apagarUnidade() {
        this.axios
        // console.log(this.concessionaria)
          .post("/unidades/apagar", this.dados_unidade)
          .then(() => {
            
            this.deleteProductDialog = false;
            this.dados_unidade = {};
            this.$toast.add({
              severity: "success",
              summary: "Sucesso",
              detail: "Tipo de unidade apagada com sucesso!",
              life: 3000,
            });
            
            this.listarUnidades();
              
          })
          .catch(() => {
            
            self.$toast.add({
              severity: "error",
              summary: "Ops!",
              detail: "Chamar o T.I.",
              life: 3000,
            });
          });
      },

      // delete endereço
      apagarEndereco() {
        this.axios
          .post("/unidades/enderecos/apagar", this.dados_endereco)
          .then(() => {
            
            this.deleteEnderecoDialog = false;
            this.dados_endereco = {};
            this.$toast.add({
              severity: "success",
              summary: "Sucesso",
              detail: "Endereço apagado com sucesso!",
              life: 3000,
            });
            
            this.modalDadosAdicionaisUnidade = false;
            this.listarUnidades();
              
          })
          .catch(() => {
            
            self.$toast.add({
              severity: "error",
              summary: "Ops!",
              detail: "Chamar o T.I.",
              life: 3000,
            });
          });
      },

      // delete cnpj
      apagarCnpj() {
        this.axios
          .post("/unidades/cnpj/apagar", this.dados_cnpj)
          .then(() => {
            
            this.deleteCnpjDialog = false;
            this.dados_cnpj = {};
            this.$toast.add({
              severity: "success",
              summary: "Sucesso",
              detail: "CNPJ apagado com sucesso!",
              life: 3000,
            });
            
            this.modalDadosAdicionaisUnidade = false;
            this.listarUnidades();
              
          })
          .catch(() => {
            
            self.$toast.add({
              severity: "error",
              summary: "Ops!",
              detail: "Chamar o T.I.",
              life: 3000,
            });
          });
      },

      // delete rateio
      apagarRateio() {
        this.axios
          .post("/unidades/rateio/apagar", this.dados_rateio)
          .then(() => {
            
            this.deleteRateioDialog = false;
            this.dados_rateio = {};
            this.$toast.add({
              severity: "success",
              summary: "Sucesso",
              detail: "Rateio apagado com sucesso!",
              life: 3000,
            });
            
            this.modalDadosAdicionaisUnidade = false;
            this.listarUnidades();
              
          })
          .catch(() => {
            
            self.$toast.add({
              severity: "error",
              summary: "Ops!",
              detail: "Chamar o T.I.",
              life: 3000,
            });
          });
      },
      
      

      // botao de excluir selecionados
      confirmDeleteSelected() {
        this.deleteProductsDialog = true;
      },

      deleteSelectedProducts() {
        this.unidades = this.unidades.filter(
          (val) => !this.selectUnidades.includes(val)
        );
 
        let obj = this.selectUnidades;
        Object.keys(obj).forEach((key) => {
          this.axios
            .post("/unidades/apagar", obj[key])
            .then(() => {
              this.deleteProductDialog = false;
              this.dados_unidade = {};
              this.$toast.add({
                severity: "success",
                summary: "Sucesso",
                detail: "Unidades apagadas com sucesso!",
                life: 3000,
              });
              this.listarUnidades();
            })
            .catch(() => {
              this.$toast.add({
                severity: "error",
                summary: "Ops!",
                detail: "Chamar o T.I.",
                life: 3000,
              });
            });
        });
 
        this.deleteProductsDialog = false;
        this.selectUnidades = null;
      },
     
     exportCSV() {
       this.$refs.dt.exportCSV();
      },
      
      
      initFilters() {
        this.filters = {
          global: { value: null, matchMode: FilterMatchMode.CONTAINS },
        };
      },
      
    },
  };


</script>
<style scoped lang="scss">
@import "../../assets/demo/badges.scss";
</style>
